<template>
  <div style="border: 1px solid #ccc; z-index: 100; height: 100%;">
    <Toolbar
      style="border-bottom: 1px solid #ccc"
      :editor="editor"
      :defaultConfig="toolbarConfig"
      :mode="mode"
    />
    <Editor
      style="height: 500px; overflow-y: hidden;"
      v-model="content"
      :defaultConfig="editorConfig"
      :mode="mode"
      @onCreated="onCreated"
      @onChange="handleEditorChange"
    />
  </div>
</template>
<script>
import { config } from '@/assets/config'
import { Editor, Toolbar } from '@wangeditor/editor-for-vue'
import { Boot } from '@wangeditor/editor'
import markdownModule from '@wangeditor/plugin-md'
Boot.registerModule(markdownModule)

export default {
  components: { Editor, Toolbar },
  props: ['contentProp'],
  name: 'PostEditor',
  data () {
    return {
      prefix: config.prefix,
      editor: null,
      content: this.contentProp,
      toolbarConfig: { },
      mode: 'default',
      editorConfig: {
        placeholder: 'Text here',
        MENU_CONF: { 
          uploadImage: {
            customUpload: async (file, insertFn) => {
              this.$axios.post('/api/article/storeimage', { imgurl: file })
              // insert to the article and db image url
              .then(res => insertFn(`${this.prefix}/${res.data.imgurl}`, `post-content-image-${file.name}`, null))
            }
          } 
        }
      }
    }
  },
  methods: {
    onCreated(editor) {
      this.editor = Object.seal(editor) // 一定要用 Object.seal() ，否则会报错
    },
    handleEditorChange() {
      this.$emit('onChange', this.content);
    }
  },
  mounted () {
    setTimeout(() => {
        this.content = this.contentProp
      }, 2500);
    },

  beforeDestroy() {
    const editor = this.editor
    if (editor == null) return
    editor.destroy()
  }
}
</script>
<style src="@wangeditor/editor/dist/css/style.css"></style>